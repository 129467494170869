import React, { useState, useEffect, useRef, useContext } from 'react';
import FullCalendar from '@fullcalendar/react';
import { formatDate } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import ClearIcon from "@material-ui/icons/Clear";
import { GlobalContext } from "context/GlobalContext";
import moment from "moment";
import API from "api";

const CertifAppointment = ({certif}) => {
    const { state, dispatch } = useContext(GlobalContext);
    const [events, setEvents] = useState([]);
    const calendarRef = useRef(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Simulate fetching data from MySQL
        const fetchData = async () => {

            API.get(`certifs/${certif.id}/appointment`)
            .then(function(res) {
                setLoading(false);
                const formattedEvents = res.data.map(event => ({
                    id: event.id,
                    title: event.user_name ?? "Disponible",
                    start: new Date(moment.parseZone(event.start_at).format("YYYY-MM-DD HH:mm:ss")),
                    end: new Date(moment.parseZone(event.end_at).format("YYYY-MM-DD HH:mm:ss")),
                    allDay: false,
                    backgroundColor: event.user_name ? "#990000" : 'green',
                    taken: event.free
                }));

                setEvents(formattedEvents);
                console.log("formattedEvents", formattedEvents)
            })
            .catch((e) => {
                dispatch({ type: "CATCH_ERROR", payload: e });
            })
            .finally(() => {
                setLoading(false);
            });
        
        };
        fetchData();
    }, []);


  const handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect();
    API.post(`certifs/${certif.id}/appointment`, {
        start_at: moment(selectInfo.startStr).local().format("YYYY-MM-DD HH:mm:ss"),
        end_at: moment(selectInfo.endStr).local().format("YYYY-MM-DD HH:mm:ss"),
    }).then(res => {
        setEvents([
            ...events,
            {
                id: res.data.id,
                title: "Disponible",
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay,
                backgroundColor: "green",
                taken: false
            },
        ]);
    })
    .catch(e => {
        dispatch({ type: "CATCH_ERROR", payload: e });
    });
  };

  const handleEventRemove = (eventId) => {
    const calendarApi = calendarRef.current.getApi();
    const event = calendarApi.getEventById(eventId);
    if (event) {
        API.delete(`certifs/${certif.id}/appointment/${event.id}`)
        .then(() => {
            event.remove();
            const filteredEvents = events.filter(e => {
                return e.id != eventId
            })

            setEvents(filteredEvents)  
        })
        .catch(e => {
            dispatch({ type: "CATCH_ERROR", payload: e });
        });
    
    }
  };

  const renderEventContent = (eventInfo) => {
    if(eventInfo.backgroundColor === "") return ""
    return (
      <div>
        <div>{eventInfo.event.title}</div>
        {eventInfo.backgroundColor !== 'green' ? <div>ID Bcloud: {eventInfo.event.id}</div> : 
        <ClearIcon
          style={{ right: 0, top: 0, position: 'absolute', cursor: 'pointer' }}
          fontSize="small"
          onClick={() => handleEventRemove(eventInfo.event.id)}
        />}
        
      </div>
    );
  };

  return (
    <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        selectable={true}
        selectMirror={true}
        select={handleDateSelect}
        events={events}
        eventContent={renderEventContent}
    />
  );
};

export default CertifAppointment;
