import React, { useEffect, forwardRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import validations from "assets/js/validations";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import API from "api";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const useStyles = makeStyles(styles);

const BcloudTable = props => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [orders, setOrders] = useState([]);

  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);

    setColumns([
      {
        title: "N°",
        field: "id",
        defaultSort: "desc",
        editable: "never",
        cellStyle: {
          width: 10,
          maxWidth: 10,
          verticalAlign: "top"
        },
        headerStyle: {
          width: 10,
          maxWidth: 10
        }
      },
      {
        title: "Statut",
        field: "status",
        lookup: {
          pending: "En attente",
          approved: "Approuvée",
          payed: "Payée",
          refunded: "Remboursée",
          cancelled: "Annulée"
        },
        cellStyle: {
          width: 130,
          maxWidth: 130,
          verticalAlign: "top"
        },
        headerStyle: {
          width: 130,
          maxWidth: 130
        }
      },
      {
        title: "Client et paiement",
        field: "user",
        editable: "never",
        render: data => (
          <div>
            {data.user.fname + " " + data.user.lname}
            <br />
            <small>{data.user.email}</small>
            <br />
            <small>
              {data.user.function} {data.user.phone ? "(" + data.user.phone + ")" : false}
            </small>

            <br />
            <b>Paiement: </b>
            <br />
            <div>
              <b>Payé: </b>
              {data.payed.formatted}
              <br />
              <small>{data.transaction ? "[" + data.transaction + "]" : false}</small>
              <b>Reste: </b>
              {data.leftToPay.formatted}<br />
              <b>Total: </b>
              {data.total.formatted}
              {data.coupon_name !== null ? (
                <div>
                <b><small>Coupon utilisé :</small></b> {data.coupon_name} <small>(-{data.coupon_value})</small>
              </div>) : null }

            </div>
          </div>
        ),
        cellStyle: {
          width: 300,
          maxWidth: 300,
          verticalAlign: "top"
        },
        headerStyle: {
          width: 300,
          maxWidth: 300
        }
      },
      {
        title: "Détails de la commande",
        render: data => 
       <div>
         {data.simulators.length > 0 ? <b>Simulateurs: </b>: false}
          { data.simulators.map((s, i) => {
            return <div>
                {s.title} : 
                <small>
                  {" Quantité: " + s.quantity} {"Prix: " + s.price.formatted}
                </small>
              </div>
          })}
          <br />
          {data.sessions.length > 0 ? <b>Sessions de formation: </b>: false}
          {data.sessions.map((s, i) => {
              return <div>
                {s.title} :
                <small>
                  {" Le: " + s.start_at} - {"Prix: " + s.price.formatted}
                </small>
              </div>
          })}
          <br />
          {data.certificates.length > 0 ? <b>Certification: </b> : false}
          {data.certificates.map((s, i) => {
              return <div>
                {s.title} - 
                <small>
                  {" Quantité: " + s.quantity} {"Prix: " + s.price.formatted}
                </small>
              </div>
          })}  
        </div>,
        editable: "never",
        cellStyle: {
          width: 600,
          maxWidth: 600
        },
        headerStyle: {
          width: 600,
          maxWidth: 600
        }
      }
    ]);
    if (ability.can("list", "orders")) {
      API.get("orders")
        .then(function(res) {
          setLoading(false);
          setOrders(res.data);
        })
        .catch(e => {
          dispatch({ type: "CATCH_ERROR", payload: e });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const paymentMethod = (method_id) => {
    if(method_id === 1) return "Virement/versement"
    else if(method_id === 4) return "Payzone"
    else if(method_id === 5) return "Paypal" 
  }


  return (
    <>
      {ability.can("list", "orders") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto"
              }}
            />
          ) : (
            <MaterialTable
              title={null}
              columns={columns}
              data={orders}
              options={{pageSize:1, pageSizeOptions: [1, 2,5,10,20]}}
              defaultSorted={[
                {
                  id: "id",
                  desc: true
                }
              ]}
              editable={{
                onRowUpdate: (newData, oldData) => 
                  new Promise((resolve, reject) => {
                    API.put("orders/" + oldData.id + "/status", {
                      status: newData.status
                    })
                      .then(() => {
                        const editedData = JSON.parse(JSON.stringify(orders));
                        const index = orders.indexOf(oldData);
                        editedData[index] = newData;
                        setOrders(editedData);

                        dispatch({
                          type: "OPEN_SNACKBAR",
                          payload: {
                            type: "success",
                            content: validations.successfullyUpdated
                          }
                        });
                      })
                      .catch(e => {
                        dispatch({ type: "CATCH_ERROR", payload: e });
                      })
                      .finally(() => {
                        setLoading(false);
                        resolve();
                      });
                  
                  })
                }}
              detailPanel={rowData => {
                return (
                  <div>
                   {rowData.payments.length > 0 ? (
                    rowData.payments.map((payment, index) => {
                      return <Box p={4}>
                        Un paiement de {payment.amount} {payment.currency} a été effectué via {paymentMethod(payment.method_id)}. 
                        {payment.receipt_number ? "N° transaction : " + payment.receipt_number : false }
                      </Box>
                    })
                   ) : "Aucun paiement pour l'instant" }
                  </div>
                );
              }}
              icons={{
                SortArrow: forwardRef((props, ref) => (
                  <KeyboardArrowDown {...props} ref={ref} />
                ))
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Lignes",
                  labelRowsPerPage: "Ligne par page",
                  firstAriaLabel: "Première page",
                  firstTooltip: "Première page",
                  previousAriaLabel: "Page précédente",
                  previousTooltip: "Page précédente",
                  nextAriaLabel: "Page suivante",
                  nextTooltip: "Page suivante",
                  lastAriaLabel: "Dernière page",
                  lastTooltip: "Dernière page"
                },
                toolbar: {
                  exportTitle: "Exporter",
                  exportName: "Exporter en format CSV",
                  searchTooltip: "Recherche",
                  searchPlaceholder: "Recherche"
                },
                header: {
                  actions: "Action"
                },
                body: {
                  addTooltip: "Ajouter",
                  deleteTooltip: "Supprimer",
                  editTooltip: "Modifier",
                  emptyDataSourceMessage: "Aucun résultat trouvé",
                  filterRow: {
                    filterTooltip: "Filter"
                  },
                  editRow: {
                    deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                    cancelTooltip: "Annuler",
                    saveTooltip: "Sauvegarder"
                  }
                }
              }}
            />
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default BcloudTable;
